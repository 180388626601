import {useStore} from '~/stores/store';

export default defineNuxtRouteMiddleware(async to => {
	const store = useStore();

	// console.log('%c- middleware ENTITIES', 'color:#0a0');

	await store.fetchEntities();

	// skip ignore on server
	// if (process.server) return
	// // skip ignore on client side entirely
	// if (process.client) return
	// // or only skip ignore on initial client load
	// const nuxtApp = useNuxtApp()
	// if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return
})
